import React from 'react'
import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts';
import GamesCardSection from '../../components/GamesCardSection/GamesCardSection';
import ProductsAll from '../../components/ProductsAll/ProductsAll';
import Faq from '../../components/faq/faq';
import Hero from '../../components/hero/hero';
import WhyUs from '../../components/why_us/why_us';
import useFetch from "../../hooks/useFetch";
import axios from 'axios';
const Home = () => {
  
  return (
    <div className='containerMain'>
      <Hero/>
      <ProductsAll/>
      <WhyUs/>
      <Faq/>
    </div>
  )
}

export default Home