import React from 'react'
import {Link} from "react-router-dom"
const Footer = () => {
  return (
    <footer class="footer">
    <div class="copyright">
      <div class="copyright-text">© 2024 Visuals</div>
    </div>
    <div class="legal-container">
      <Link to="/contact" class="contact-us-link w-inline-block">
        <div class="contact-us">
          <div class="contact-us-text">Contact Us</div>
        </div>
      </Link>
      <Link to="/policies" class="privacy-policy-link w-inline-block">
        <div class="privacy-policy">
          <div class="privacy-policy-text">Policies</div>
        </div>
      </Link>
      <Link to="/tos" class="terms-of-service-link w-inline-block">
        <div class="terms-of-service">
          <div class="terms-of-service-text">Terms</div>
        </div>
      </Link>
    </div>
  </footer>
  )
}

export default Footer