import React from 'react';

/*<div id="w-node-f6c5f96d-cd08-a362-afd0-3cb8efb92b91-0bd1a1c6" class="contact-option">
            <div id="w-node-f6c5f96d-cd08-a362-afd0-3cb8efb92b92-0bd1a1c6" class="contact-option-heading">2</div>
            <div id="w-node-f6c5f96d-cd08-a362-afd0-3cb8efb92b94-0bd1a1c6" class="contact-option-content"><span class="contact-option-icon-span"></span> Live Chat</div>
            <div class="contact-livechat-description">The live chat button is always located at the bottom right corner of the website</div> 
        </div>*/

const Contact = () => {
 //We apologize, this option is temporarily unavailable
  return (
    <>
    <div class="contact-flex">
        <div class="contact-heading">Contact Us</div>
        <div class="contact-description">There are three ways you can contact us through</div>
        <div class="contact-ways">
        <div id="w-node-f0c31104-2e64-413d-5378-7d8c09efdff5-0bd1a1c6" class="contact-option">
            <div id="w-node-_64a14c50-7c91-7694-6a97-94fe7ab7fde1-0bd1a1c6" class="contact-option-heading">1</div>
            <div id="w-node-c483aac2-5069-5df5-9ac9-628920b005cf-0bd1a1c6" class="contact-option-content"><span class="contact-option-icon-brand-span"></span> Discord</div>
            <a href="https://discord.gg/z7CJcyMXWm" target="blank" class="contact-option-button w-button">Join</a>
        </div>
        
        <div id="w-node-efbb0e70-f8cf-55be-f0a5-e4303bbe4391-0bd1a1c6" class="contact-option">
            <div id="w-node-efbb0e70-f8cf-55be-f0a5-e4303bbe4392-0bd1a1c6" class="contact-option-heading">2</div>
            <div id="w-node-efbb0e70-f8cf-55be-f0a5-e4303bbe4394-0bd1a1c6" class="contact-option-content"><span class="contact-option-icon-span"></span> Email</div>
            <a href="mailto:contact@visuals.gg" class="contact-option-button w-button">Write</a>
        </div>
        </div>
    </div>
    </>
  );
}

export default Contact;