import React, { useEffect,useState } from 'react';
import { useAuthContext } from "../../context/AuthContext";
import { removeToken} from "../../helpers";
import useFetch from "../../hooks/useFetch";
import {Link,useLocation } from "react-router-dom"
import axios from 'axios';
import {resetCart} from "../../redux/cartReducer";
import { useDispatch } from "react-redux";
import StatusProducts from '../../components/statusProducts/statusProducts';
import LatestVideo from '../../components/LatestVideo/LatestVideo';
import Loader from '../../components/loader/loader';
import { toast,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeRequest } from '../../makeRequest';
import {Helmet} from "react-helmet";
const Dashboard = () => {
    // Funktion zum Extrahieren von URL-Parametern
    const getURLParameters = () => {
      const params = new URLSearchParams(window.location.search);
      return {
        session_id: params.get("session_id"),
        order_id: params.get("order_id"),
        user_id: params.get("user_id"),
      };
    };

    const dispatch = useDispatch();
    const { session_id, order_id, user_id } = getURLParameters();
    const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);

      const handleOrderConfirmation =  () => {
        setIsConfirmingOrder(true)
        makeRequest.post(`/orders/confirm`, {
          orderId: order_id,
          checkout_session: session_id,
          userid: user_id,
        })
        .then((response) => {
          // Hier können Sie die Antwort von Strapi verarbeiten, wenn gewünscht.
          dispatch(
            resetCart()
          );
          
          // Hier ändern Sie die URL
          window.location.replace(`${process.env.REACT_APP_URL}dashboard/orders`);
          setIsConfirmingOrder(false)
        })
        .catch((error) => {
          // Hier können Sie Fehlerhandhabung implementieren, wenn die Bestätigung fehlschlägt.
          console.error('Fehler beim Bestätigen der Bestellung:', error);
        })
      };
      
      useEffect(() => {
        if (session_id) {
          handleOrderConfirmation();
        }
      }, [session_id]);
      const { user,setUser  } = useAuthContext();
      const userToken = localStorage.getItem("authToken");

  if (!userToken) {
    window.location.replace(`${process.env.REACT_APP_URL}login`);
  }
  
  const userId = user && user.id; // Stelle sicher, dass user und user.id vorhanden sind
  const { data,loading } = useFetch(
    userId ? `/orders?populate=*&filters[users_permissions_user][id][$eq]=${userId}&filters[status][$eq]=paid` : null
  );

  const location = useLocation();
  const [isOrdersPage, setIsOrdersPage] = useState(location.pathname.startsWith('/dashboard/orders'));
  const [isRewardPage, setIsRewardPage] = useState(location.pathname.startsWith('/dashboard/reward'));
  
  useEffect(() => {
    // Aktualisiere isOrdersPage, wenn sich die URL ändert
    setIsOrdersPage(location.pathname.startsWith('/dashboard/orders'));
    setIsRewardPage(location.pathname.startsWith('/dashboard/reward'));
  }, [location.pathname]);

  const handleLogout = () => {
    removeToken();
    setUser();
  };

  const handleCopyKey = (key) => {
    // Erstelle ein unsichtbares Textarea-Element
    const textarea = document.createElement('textarea');
    // Setze den zu kopierenden Text
    textarea.value = key;
    // Füge das Element zum DOM hinzu
    document.body.appendChild(textarea);
    // Selektiere den Text
    textarea.select();
    // Kopiere den Text
    document.execCommand('copy');
    // Entferne das Textarea-Element aus dem DOM
    document.body.removeChild(textarea);
  
    // Zeige die Benachrichtigung an
    showCopiedNotification();
  };
  
  const showCopiedNotification = () => {
    toast.success(`Copied`, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      toastId : "1",
      transition: Slide,
      });
    // Hier kannst du eine benutzerdefinierte Benachrichtigung implementieren
    // oder eine Toast-Bibliothek wie react-toastify verwenden.
  };
  const [isDashboardNavbarOpen, setDashboardNavbarOpen] = useState(false);

  const toggleDashboardNavbar = () => {
    setDashboardNavbarOpen(!isDashboardNavbarOpen);
  };
  const closeDashboardNavbar = () => {
    setDashboardNavbarOpen(false);
  };

  

  

  const downloadAcc = (fileURL) => {
   
    fetch(fileURL)
      .then(response => response.text())
      .then(content => {
       
        var dataURL = "data:text/plain;charset=utf-8," + encodeURIComponent(content);
  
       
        var dl = document.createElement("a");
        document.body.appendChild(dl);
        dl.setAttribute("href", dataURL);
        dl.setAttribute("download", "Account_Login_Data.txt");
        dl.click();
      })
      .catch(error => console.error('Fehler beim Abrufen der Datei:', error));
  };

  const [showNoOrdersText, setShowNoOrdersText] = useState(false);
  
  useEffect(() => {
    
    const timeout = setTimeout(() => {
      setShowNoOrdersText(true);
    }, 2000); 

    return () => clearTimeout(timeout); 
  }, []); 

  return (
    <div class="dashboard-flex">
     
      <a class="mobile-menu-icon-flex w-inline-block" onClick={toggleDashboardNavbar}>
        <div class="mobile-menu-icon"></div>
      </a>
      <div class={`dashboard-navbar-fixed`}>
        <div class={`dashboard-navbar ${isDashboardNavbarOpen ? 'dashboard-navbar-mobile' : ''}`}>
          <div class="dashboard-navbar-close-flex" onClick={closeDashboardNavbar}>
            <div class="dashboard-navbar-close-icon"></div>
          </div>
          <div class="dashboard-navbar-flex">
            <div class="dashboard-navbar-inner-flex">
              <Link to="/dashboard" aria-current="page" class={`dashboard-navbar-link w-inline-block ${!isOrdersPage && !isRewardPage ? 'dashboard-navbar-link-selected' : ''}`} onClick={closeDashboardNavbar}>
                <div class={`dashboard-navbar-icon ${!isOrdersPage && !isRewardPage ? 'dashboard-navbar-icon-selected' : ''}`}></div>
                <div class={`dashboard-navbar-text ${!isOrdersPage && !isRewardPage ? 'dashboard-navbar-text-selected' : ''}`}>Dashboard</div>
              </Link>
              <Link to="/dashboard/orders" class={`dashboard-navbar-link w-inline-block ${isOrdersPage ? 'dashboard-navbar-link-selected' : ''}`} onClick={closeDashboardNavbar}>
                <div class={`dashboard-navbar-icon ${isOrdersPage ? 'dashboard-navbar-icon-selected' : ''}`}></div>
                <div class={`dashboard-navbar-text ${isOrdersPage ? 'dashboard-navbar-text-selected' : ''}`}>Orders</div>
              </Link>
              <Link to="/dashboard/reward" class={`dashboard-navbar-link w-inline-block ${isRewardPage ? 'dashboard-navbar-link-selected' : ''}`} onClick={closeDashboardNavbar}>
                <div class={`dashboard-navbar-icon ${isRewardPage ? 'dashboard-navbar-icon-selected' : ''}`}></div>
                <div class={`dashboard-navbar-text ${isRewardPage ? 'dashboard-navbar-text-selected' : ''}`}>Free Keys</div>
              </Link>
              <Link to="/#products"  class="dashboard-navbar-link w-inline-block">
                <div class="dashboard-navbar-icon"></div>
                <div class="dashboard-navbar-text">Store</div>
              </Link>
              <Link to="/status" class="dashboard-navbar-link w-inline-block">
                <div class="dashboard-navbar-icon"></div>
                <div class="dashboard-navbar-text">Status</div>
              </Link>
              <a href="https://discord.gg/z7CJcyMXWm" target="blank" class="dashboard-navbar-link w-inline-block">
                <div class="dashboard-navbar-icon dashboard-navbar-discord-icon"></div>
                <div class="dashboard-navbar-text">Discord</div>
              </a>
              <a  onClick={handleLogout} class="dashboard-navbar-link dashboard-navbar-logout-button w-inline-block">
                <div class="dashboard-navbar-icon"></div>
                <div class="dashboard-navbar-text">Log out</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {user ? (
        <> 
        {!isConfirmingOrder ? (

          <>
          {!isOrdersPage && !isRewardPage ? (
            <>
            <div class="dashboard-content">
              <div class="dashboards-stats-flex">
                <div class="dashboard-stat">
                  <div class="dashboard-stat-value">
                    <div class="dashboard-stat-value-name">Logged in as</div>
                    <div class="dashboard-stat-value-text">{user.email}</div>
                  </div>
                  <div class="dashboard-stat-icon" onClick={() => handleCopyKey(user.email)}>@</div>
                </div>
                <div class="dashboard-stat">
                  <div class="dashboard-stat-value">
                    <div class="dashboard-stat-value-name">User ID</div>
                    <div class="dashboard-stat-value-text">{user.id}</div>
                  </div>
                  <div class="dashboard-stat-icon" onClick={() => handleCopyKey(user.id)}></div>
                </div>
                <div class="dashboard-stat last-stat">
                  <div class="dashboard-stat-value">
                    <div class="dashboard-stat-value-name">Orders</div>
                    <div class="dashboard-stat-value-text">{data ? data.length : 0}</div>
                  </div>
                  <Link to="/dashboard/orders" onClick={closeDashboardNavbar}>
                    <div class="dashboard-stat-icon"></div>
                  </Link>
                </div>
              </div>

             <LatestVideo/>
            </div>
          </>

          ) : isOrdersPage ? (
           
            <>
                  
      {data && data.length > 0 ? (
        <div class="dashboard-content">

       <>
        {data.slice().reverse().map((order) => (
          <>
            {order.attributes.products.map((product) => (
              <>
              <div class="order">
                <div class="order-left">
                <Link to={`/${product?.gameNameUrl}/${product?.nameUrl}`}>
                  <img src={process.env.REACT_APP_UPLOAD_URL + product.img} loading="lazy" width="150" sizes="(max-width: 1439px) 100vw, 150px" alt="" class="order-image"/>
                </Link>
                <div class="orders-info">
                  <div class="order-product">
                    <div class="order-product-heading">Product</div>
                    <Link to={`/${product?.gameNameUrl}/${product?.nameUrl}`} class="order-product-box-flex">
                      <div id="w-node-_151001a1-e09c-eb86-4cbc-75fe0d3f567d-44f53c1a" class="order-product-text-flex">
                        <div class="order-product-text">{product.name}</div>
                        <div id="w-node-fb9440d3-bad9-a7ca-c3af-64172c78451e-44f53c1a" class="order-product-game">{product.gameName}</div>
                      </div>
                      <div class="order-duration">{product.duration}</div>
                    </Link>
                  </div>
                  <Link to="/status" class="order-status">
                    <div class="order-status-heading">Status</div>
                    <StatusProducts orderdata={product}/>
                  </Link>

                  <div class="order-id">
                    <div class="order-id-heading">Order ID</div>
                    <div class="order-id-text">{order.id}</div>
                  </div>
                
                  <div class="order-key">
                   
                    
                      <>
                        {product.type === 'Key' && (
                          <div class="order-key-heading">Key</div>
                        )}
                        {product.type === 'Account' && (
                          <div class="order-key-heading">Login data</div>
                        )}
                        {order.attributes.keys && order.attributes.keys.length > 0 && (
                          <div class="order-key-flex">
                            {order.attributes.keys
                              .filter((key) => key.productId === product.id && key.duration === product.duration)
                              .map((key) => (
                                <>
                                {product.type === 'Key' && (
                                  <>
                                <div key={key.id} class="order-key-content">
                                  {key.serialkey}
                                </div>
                                <a class="order-key-copy-button w-inline-block" onClick={() => handleCopyKey(key.serialkey)}>
                                  <div class="order-key-copy-icon"></div>
                                </a>
                                </>
                                )}
                                {product.type === 'Account' && (
                                  <>
                                <a class="instructions-download-button w-inline-block" key={key.id} onClick={() => downloadAcc(key.serialkey)} title="Download as SVG file.">
                                  <div class="loader-download-button-text">DOWNLOAD</div>
                                </a>     
                                 
                                </>
                                )}
                                </>
                               ))}
                        </div>
                      )}
                      </>
                    
                  </div>
                </div>
              </div>

              <div class="orders-downloads">
                <Loader orderdata={product}/>
              </div>

            </div>
            </>
            ))}
            </>
        ))}
        </>
        </div>
        ) : (
          <>
            {loading ? (
              <div class="dashboard-no-order-text">
                <svg
                class="container"
                viewBox="0 0 40 40"
                height="40"
                width="40"
              >
                <circle 
                  class="track"
                  cx="20" 
                  cy="20" 
                  r="17.5" 
                  pathlength="100" 
                  stroke-width="5px" 
                  fill="none" 
                />
                <circle 
                  class="car"
                  cx="20" 
                  cy="20" 
                  r="17.5" 
                  pathlength="100" 
                  stroke-width="5px" 
                  fill="none" 
                />
              </svg>
              </div>
            ) : (
              showNoOrdersText && (
                <div className="dashboard-no-order-text">
                  <p className="noPurchase-text">No orders yet</p>
                </div>
              )
            )}
          </>
        )}
        
            </>
          ) : (
            // Hier fügst du den Code für die Anzeige der "Free Keys"-Seite ein
            <div class="dashboard-content">
              <div class="rewards-content">
                  <div class="rewards-heading">Reward Program</div>
                  <div class="rewards-section-flex">
                    <div class="rewards-reward">Rewards</div>
                    <div class="rewards-reward-content">• Once your video reaches <strong>500 views</strong>, you will get a<strong> free day key </strong>of your choice<strong><br/></strong>• You can get more <strong>free day, week, month or even lifetime keys</strong> if you make more videos<br/>• The <span class="media-role-span"><strong>@Media</strong></span> role on our discord</div>
                  </div>
                  <div class="rewards-section-flex">
                    <div class="rewards-requirements">Requirements</div>
                    <div class="rewards-requirements-content">• Knowledge on how to make clips &amp; record with OBS etc.</div>
                  </div>
                  <div class="rewards-section-flex">
                    <div class="rewards-how-it-works">How it works</div>
                    <div class="rewards-how-it-works-content">1. Buy a key from our store (the product you want to make a video about)<br/>2. Make clips of kills or a showcase of the product<br/>3. Edit a video and upload it on youtube<br/>‍</div>
                  </div>
                  <div class="rewards-section-flex">
                    <div class="rewards-video">Video requirements</div>
                    <div class="rewards-video-content">• 1080p or higher<br/>• 60 FPS<br/>• 2-4 minutes long<br/>• Short Menu Showcase if there is a menu<br/>• Use methods to boost your videos views, for example tags in the description<br/>• Provide our Website &amp; Discord in the description: <a target="_blank" href="https://visuals.gg" class="rewards-discord-link">https://visuals.gg</a> &amp; <a href="https://discord.gg/z7CJcyMXWm" target="_blank" class="rewards-discord-link">https://discord.gg/z7CJcyMXWm<br/></a>
                    </div>
                    <div class="rewards-video-example">Video Example: <a class="rewards-discord-link" target="_blank" href='https://www.youtube.com/watch?v=q5pAxHsaOv4'>https://www.youtube.com/watch?v=q5pAxHsaOv4</a></div>
                  </div>
                  <div class="rewards-section-flex">
                    <div class="rewards-submit">Your video is finished?</div>
                    <a href="https://discord.gg/z7CJcyMXWm" target="_blank" class="button-2 w-button">Submit</a>
                  </div>
                </div>
              </div>
          )}
        </>
       

        ) : (
          <div class="order-confirming">
            <svg
            class="container"
            viewBox="0 0 40 40"
            height="40"
            width="40"
          >
            <circle 
              class="track"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
            <circle 
              class="car"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
          </svg>
          </div>
        )}
      </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Dashboard