import React, { useState, useEffect } from 'react';
import Card from '../Card/Card';
import useFetch from "../../hooks/useFetch";
import Gamesbuttons from '../../components/Gamesbuttons/Gamesbuttons';
import { useLocation,useNavigate} from 'react-router-dom';

const ProductsAll = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);
    if (queryParameters.get('success') === 'false') {
      localStorage.removeItem('redirectToCheckout');
    }
    const [selectedGame, setSelectedGame] = useState(null);
    const { data: products, loading, error } = useFetch(`/products?populate=*&sort=rank:asc`);
    // Setze filteredProducts auf alle Produkte zu Beginn
    const [filteredProducts, setFilteredProducts] = useState(products);
    // Funktion, die aufgerufen wird, wenn ein Spiel ausgewählt wird
    const handleGameSelect = (gameId) => {
        setSelectedGame(gameId);
    };


    useEffect(() => {
        // Filtern der Produkte basierend auf dem ausgewählten Spiel
        if (selectedGame !== null) {
            const filtered = products.filter(product => product.attributes.game.data.id === selectedGame);
            setFilteredProducts(filtered);
        } else {
            // Wenn kein Spiel ausgewählt ist, zeige alle Produkte
            setFilteredProducts(products);
        }
    }, [selectedGame, products]);

    return (
        <div id="products" class="products">
            <div class="products-heading">
            <div class="products-section-text">Store</div>
            <div class="products-heading-text">Our products</div>
            <div class="products-heading-description-text">We offer you the best premium products in the industry at great prices, our products are always curated by our highly skilled staff.</div>
        </div>




           
                <Gamesbuttons onGameSelect={handleGameSelect} selectedGame={selectedGame}/>
                <div className="products-grid">
                    {error ? "" :
                        loading ? "" :
                            filteredProducts.map(item => (
                                <Card item={item} key={item.id} />
                            ))
                    }
                </div>
         
        </div>
    );
}

export default ProductsAll;