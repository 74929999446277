import React from 'react'
import {Link} from "react-router-dom"

const Card = ({item}) => {
  return (
  


<div class="card-active">
      <Link to={`/${item?.attributes.gameNameUrl}/${item?.attributes.nameUrl}`}>
        <img alt="" src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.img?.data?.attributes?.url} class="card-image-1" />
      </Link>
      <div class="card-data">
        <div class="card-title">
          <div class="card-title-divider">
            <div class="card-title-name">{item?.attributes.name}</div>
            <div class="card-title-game">{item?.attributes.gameName}</div>
          </div>
        </div>

        <div class="card-price-button">
          <div class="card-price">
            <div class="card-price-text"><span class="card-price-from-span">from</span> <br/> ‍<span class="card-price-span">{item?.attributes.duration[0]?.price}€</span></div>
          </div>
          <Link to={`/${item?.attributes.gameNameUrl}/${item?.attributes.nameUrl}`} class="card-button-link w-inline-block">
            <div class="card-button">
              <div class="card-button-text">View</div>
            </div>
          </Link>
        </div>
      </div>
     </div>

  
  )
}

export default Card