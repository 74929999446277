import React, { useEffect, useState } from 'react';
import useFetch from "../../hooks/useFetch";

const LatestVideo = () => {
  const { data, loading } = useFetch(`/latest-video/?populate=*`);


  return (
    <>
    <div class="latestVideo">
      
      <div class="showcase-heading">
        <div class="showcase-heading-text">Our latest video</div>
      </div>
      <iframe title="YouTube video player" src={data?.attributes?.youtubeLink} frameborder="0" allowfullscreen="allowfullscreen" class="latestVideo-embed"></iframe>
    </div>
    </>
  );
};

export default LatestVideo;