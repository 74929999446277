import React, { useEffect,useState } from 'react';
import useFetch from "../../hooks/useFetch";

const StatusProducts = ({ orderdata }) => {
  const [productStatus, setProductStatus] = useState(null);
  const [productDetection, setProductDetection] = useState(null);
  const [productType, setProductType] = useState(null);
  const { data: products, loading } = useFetch(`/products/?populate=*`);
  useEffect(() => {
    if (!loading && products && orderdata) {
      // Finde das entsprechende Produkt in der products-Liste
      const product = products.find((p) => p.id === orderdata.id);
      if (product) {
        setProductStatus(product.attributes.status);
        setProductDetection(product.attributes.detection);
        setProductType(product.attributes.type);
      }
    }
  }, [loading, products, orderdata]);

  const getStatusClass = (status) => {
    switch (status) {
      case 'Undetected':
        return 'order-status-text-undetected';
      case 'Testing':
        return 'order-status-text-updating';
      case 'Up to date':
        return 'order-status-text-undetected';
      case 'Working':
        return 'order-status-text-undetected';
      case 'Offline':
        return 'order-status-text-detected';
      case 'Updating':
        return 'order-status-text-updating';
      case 'Use at own risk':
        return 'order-status-text-use-at-own-risk';
      default:
        return '';
    }
  };

  return (
    <>
      {loading ? "" : productStatus && (
        <>
        <div className="status-products-order-container">
          <div class={`${getStatusClass(productStatus)} status-product-box-order`}>
            {productStatus}

          </div>
          {productType === 'Key'  && (
            <>
            <div className="status-product-and-order">&</div>
            <div class={`${getStatusClass(productDetection)} status-product-box-order`}>
            {productDetection}
            </div>
            </>
          )}
      </div>
        </>
      )}
    </>
  );
};

export default StatusProducts;