import React, { useEffect } from 'react';

const Discord = () => {
  useEffect(() => {
    // Redirect to the Discord invite URL
    window.location.href = 'https://discord.gg/z7CJcyMXWm';
  }, []);

  // You can also render some content here if needed
  return (
    <>
    <div class="authentication-flex space">
    <svg
      class="container"
      viewBox="0 0 40 40"
      height="40"
      width="40"
    >
      <circle 
        class="track"
        cx="20" 
        cy="20" 
        r="17.5" 
        pathlength="100" 
        stroke-width="5px" 
        fill="none" 
      />
      <circle 
        class="car"
        cx="20" 
        cy="20" 
        r="17.5" 
        pathlength="100" 
        stroke-width="5px" 
        fill="none" 
      />
    </svg>
  </div>
  <div class="push-product"></div>
  </>
  );
};

export default Discord;