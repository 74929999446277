import React from 'react';
const Faq = () => {


  return (
    <div class="faq">
        <div class="faq-section">
        <div class="faq-section-text">FAQ</div>
        </div>
        <div class="faq-heading">
        <div class="features-heading-text">Frequently Asked Questions</div>
        </div>
        <div class="faq-cards">
        <div id="w-node-_6ce0050b-4b04-9007-e620-e5da18337e0f-34231836" class="faq-card">
            <div id="w-node-_37cf4bca-5103-dedd-ea9e-592ba286e5a4-34231836" class="faq-card-heading">Are your payment methods safe to use?</div>
            <div class="faq-card-content">Absolutely. We use trusted third-party providers such as PayPal and Stripe to handle all payments on our website, ensuring that your financial information is secure. You can rest assured that our payment methods are safe to use.</div>
        </div>
        <div id="w-node-_184ce73a-cb34-540d-2622-8b09219e394d-34231836" class="faq-card">
            <div id="w-node-_184ce73a-cb34-540d-2622-8b09219e394e-34231836" class="faq-card-heading">Can I cancel my order after I&#x27;ve placed it?</div>
            <div class="faq-card-content">Once an order has been confirmed, it is final and cannot be cancelled or refunded. We encourage you to carefully review your order before submitting it to ensure that it meets your needs and requirements.</div>
        </div>
        <div id="w-node-_0a60021b-6321-b797-8df0-3bf053d237c9-34231836" class="faq-card">
            <div id="w-node-_0a60021b-6321-b797-8df0-3bf053d237ca-34231836" class="faq-card-heading">Do you provide support?</div>
            <div class="faq-card-content">Yes, we provide support to all of our customers. Our customer support team is available to assist you with any questions, concerns, or technical issues that you may encounter while using our products or services.</div>
        </div>
        <div id="w-node-dd8be00a-c050-c5c1-631f-bb57b55a9b1f-34231836" class="faq-card">
            <div id="w-node-dd8be00a-c050-c5c1-631f-bb57b55a9b20-34231836" class="faq-card-heading">Is my product delivered instantly?</div>
            <div class="faq-card-content">Sure! All our products are automatically delivered to your dashboard account immediately after successfully completing checkout. Note that payments in cryptocurrency can usually take from 5 to 30 minutes to complete.</div>
        </div>
        </div>
    </div>
  );
};

export default Faq;