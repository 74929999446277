import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
     const item = state.products.find(item =>item.id === action.payload.id && item.duration === action.payload.duration)
     if (item) {
      item.quantity += action.payload.quantity;
     }else{ 
      state.products.push(action.payload); 
     }
    },
    itemQuanMinus: (state, action) => {
      const item = state.products.find(item => item.id === action.payload.id && item.duration === action.payload.duration)
      item.quantity -= action.payload.quantity;
      item.total = item.quantity * item.Price;
      if (item.quantity < 1) {
        state.products=state.products.filter(item=>!(item.id === action.payload.id && item.duration === action.payload.duration))
      }
    },
    itemQuanPlus: (state, action) => {
      const item = state.products.find(item => item.id === action.payload.id && item.duration === action.payload.duration)
      item.quantity += action.payload.quantity;
      item.total = item.quantity * item.Price;
    },
    resetCart: (state) => {
      state.products = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { addToCart,itemQuanMinus,resetCart,itemQuanPlus} = cartSlice.actions

export default cartSlice.reducer;