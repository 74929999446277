import React, { useEffect,useState } from 'react';
import axios from 'axios';

const Confirm = () => {

    const getURLParameters = () => {
        const params = new URLSearchParams(window.location.search);
        return {
          session_id: params.get("session_id"),
          order_id: params.get("order_id"),
        };
      };
    
      const { session_id, order_id } = getURLParameters();
    
      
      const [isOrderConfirming, setIsOrderConfirming] = useState(false);

      const handleOrderConfirmation = async () => {
        setIsOrderConfirming(true);
        try {
          // Hier sollten Sie den Bestellstatus überprüfen, bevor Sie die Bestellung bestätigen
          const response = await axios.get(`https://api.visuals.gg/api/orders/${order_id}`);
          const order = response.data;
    
          if (order.data.attributes.status === 'unpaid') {
            // Bestellung bestätigen 
            axios
            .post(`https://api.visuals.gg/api/orders/confirm`, {
              orderId: order_id,
              checkout_session: session_id,
            })
            .then(response => {
             
    
            })
            .catch(error => {
              // Handle error.
              console.log('An error occurred:', error.response);
            });
    
           
           
          }
        } catch (error) {
         
        } finally {
          
        }
      };
    
      useEffect(() => {
        if (session_id) {
          handleOrderConfirmation();
        }
      }, []);

  return (
    <div></div>
  )
}

export default Confirm