import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css';
import './webflow.css';
import './visuals-gg.webflow.css';
import App from './App';
import AuthProvider from "./components/AuthProvider/AuthProvider";
import reportWebVitals from './reportWebVitals';
import { persistor, store } from "./redux/store";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
        <Provider store={store}>
          <PersistGate loading={"loading"} persistor={persistor}>
            <App />
            <ToastContainer/>
          </PersistGate>
        </Provider>
    </AuthProvider>
  </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
