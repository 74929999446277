import React, {useEffect } from 'react';
const Hero = () => {
  const handleButtonClick = () => {
    const productsElement = document.getElementById('products');
    if (productsElement) {
      productsElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div class="hero">
    <div class="hero-container">
      <div class="hero-heading">
        <div class="heading-text">We provide the <span class="edge-colored">edge</span> you need to dominate</div>
      </div>
      <div class="hero-description">
        <div class="hero-description-text">Our premium cheats redefine the gaming experience</div>
      </div>
      <div class="hero-buttons">
        <a  class="hero-button-products w-inline-block" onClick={handleButtonClick}>
          <div class="hero-button-products-design">
            <div class="hero-button-products-text">View products</div>
          </div>
        </a>
      </div>
      <div class="scroller">
        <div class="scroller-icon"></div>
      </div>
    </div>
  </div>
  );
};

export default Hero;