import React, { useEffect,useState } from 'react';
import useFetch from "../../hooks/useFetch";
import {Link } from "react-router-dom"
const WhyUs = () => {


  return (
    <div class="why-us">
    <div class="features-section">
      <div class="features-section-text">Why us?</div>
    </div>
    <div class="features-heading">
      <div class="features-heading-text">What sets us apart</div>
    </div>
    <div class="whyus-cards">
      <div id="w-node-fa8f9122-1b96-f058-82be-b1c4a400d193-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">Instant delivery</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">The product and download are instantly available after purchase.</div>
        </div>
      </div>
      <div id="w-node-_199c15d4-968b-2fab-b877-4a2e434f5ab8-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">Highest safety &amp; quality</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">We offer you the safest and best premium products on the market.</div>
        </div>
      </div>
      <div id="w-node-eb2bf440-1c89-0b25-d004-783e67287db4-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">Easy to use</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">We provide step by step instructions for every product we offer so we make it as easy as it can be for you.</div>
        </div>
      </div>
      <div id="w-node-_93a9adda-0ffc-6528-f6b6-f24d7a9d4082-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">24/7 Support</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">We offer lightning fast support via our built-in live chat, discord or email.</div>
        </div>
      </div>
      <div id="w-node-_140c4b9c-30ea-5691-a30a-248a8c8551bc-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">Your own dashboard</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">Every key, every download, instructions and much more in just one place.</div>
        </div>
      </div>
      <div id="w-node-_11a1246f-3106-b4c0-b903-b8f266de4bee-34231836" class="whyus-card">
        <div class="system-icon-background">
          <div class="system-card-icon"></div>
        </div>
        <div class="system-text-container">
          <div class="system-card-text">Secure payments</div>
        </div>
        <div class="system-card-description">
          <div class="system-card-description-text">We use trusted third-party providers such as PayPal and Stripe to handle all payments on our website, ensuring that your financial information is secure.</div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default WhyUs;