import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from "../../context/AuthContext";
import { setToken } from "../../helpers";
import {loadStripe} from '@stripe/stripe-js';
import { makeRequest } from '../../makeRequest';
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import { toast,Slide } from "react-toastify";

const Authentication = () => {
      const [requestSuccess, setRequestSuccess] = useState(true);
      
      const queryParameters = new URLSearchParams(window.location.search)
      const loginToken = queryParameters.get("loginToken")
      
      const { user,setUser  } = useAuthContext();
      const options = {
        credentials: "include",
        withCredentials: true,
      };



     
      //const products = localStorage.getItem('products');
      const redirectToCheckout = localStorage.getItem('redirectToCheckout');
      const selectedPaymentMethod = localStorage.getItem('selectedPaymentMethod');
      const handelType = localStorage.getItem('handelType');
      const [email, setEmail] = useState([]);
      useEffect(() => {
        if (user && user.email) {
          setEmail(user.email);
        }
      }, [user]);

      makeRequest.get(`https://api.visuals.gg/api/passwordless/login?loginToken=${loginToken}`, options, {
    })
    .then(response => {
     
       setToken(response.data.jwt);
  
       // set the user
       setUser(response.data.user);

       if (redirectToCheckout === 'true') {
       } else{
        window.location.replace(`${process.env.REACT_APP_URL}dashboard`);
       }

    })
    .catch(error => {
      
       setRequestSuccess(false);
    });

      const storedProducts = JSON.parse(localStorage.getItem('products')) || []; // Produkte aus dem Local Storage holen
      const productsFromRedux = useSelector((state) => state.cart.products);
      const [products, setProducts] = useState(handelType === "buyNow" ? storedProducts : productsFromRedux);
      
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
     
      const [isProcessing, setIsProcessing] = useState(false);

      const handlePayment = async (paymentMethod) => {
       
        if (isProcessing == true){
        
          return;
        };
       
        setIsProcessing(true);
        try {
         
      
          const notAvailableProducts = []; 
      
          for (const product of products) {
          
            const response = await makeRequest.put(`/stockCounter`, { id: product.id });
            const productInData = response.data;
      
            
            if (productInData) {
              let productOutOfStock = false;
      
              for (const durationItem of productInData) {
                if (durationItem.durationtime === product.duration) {
                  const counter = durationItem.keyCount;
                  const purchasedQuantity = product.quantity || 1; 
      
                  if (counter <= 0) {
                    notAvailableProducts.push({
                      name: `${product.gameName} ${product.name} ${product.duration} Out of stock`,
                    });
                    productOutOfStock = true;
                    break;
                  }
      
                  if (counter < purchasedQuantity) {
                    const remainingKeysInfo = counter > 0 ? `(only ${counter} left)` : '';
                    notAvailableProducts.push({
                      name: `${product.gameName} ${product.name} ${product.duration} ${remainingKeysInfo}`,
                    });
                    productOutOfStock = true;
                    break;
                  }
                }
              }
      
              if (productOutOfStock) {
                continue;
              }
            }
      
          }
      
          
          if (notAvailableProducts.length > 0) {
            const notAvailableProductNames = notAvailableProducts.map(
              (product) => `${product.name}`
            ).join(", ");
            toast.error(`${notAvailableProductNames}`, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              toastId: "3",
              transition: Slide,
            });
            setTimeout(() => {
              window.location.replace(`${process.env.REACT_APP_URL}#products`);
            }, 4000);          
            
            return;
          }
          
          if(products  && user && selectedPaymentMethod){
            const stripe = await stripePromise;
           
            const res = await makeRequest.post("/orders", {
              products,
              email: user.email,
              user,
              selectedPaymentMethod: paymentMethod,
              currentUrl: `${process.env.REACT_APP_URL}`,
            });
            
            await stripe.redirectToCheckout({
              sessionId: res.data.stripeSession.id,
            });
           
            
          }
          
        } catch (error) {
          console.error('Fehler', error);
        }
      };

      

    

    useEffect(() => {
      if (redirectToCheckout === 'true' && products  && user && selectedPaymentMethod) {
        handlePayment(selectedPaymentMethod);
      }
      // Weitere Abhängigkeiten können hier hinzugefügt werden, falls erforderlich
    }, [user,products,selectedPaymentMethod]);
    if (redirectToCheckout === 'true') {
      return (
        <>
        <div class="authentication-flex">
          <div class="img-flex">
            <img src={`${process.env.REACT_APP_UPLOAD_URL}/uploads/banner_transparent_b98abd20b8.png`} loading="lazy" width="500" sizes="(max-width: 767px) 100vw, 500px" alt=""  class="authentication-logo"/>
          </div>
          <svg
            class="container"
            viewBox="0 0 40 40"
            height="40"
            width="40"
          >
            <circle 
              class="track"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
            <circle 
              class="car"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
          </svg>
        </div>
      </>
      );
    }
  
    if (!requestSuccess) {
      // Something went wrong
      return (
        <div class="something-went-wrong">
          <div class="something-went-wrong-text">Something went wrong.</div>
          <Link to="/login" class="login-again-button w-inline-block">
            <div class="login-again-button-text">Login again</div>
          </Link>
          <div class="push"></div>
        </div>
      );
    }
    
    
    return (
      <>
        <div class="authentication-flex">
          <div class="img-flex">
            <img src={`${process.env.REACT_APP_UPLOAD_URL}/uploads/banner_transparent_b98abd20b8.png`} loading="lazy" width="500" sizes="(max-width: 767px) 100vw, 500px" alt=""  class="authentication-logo"/>
          </div>
          <svg
            class="container"
            viewBox="0 0 40 40"
            height="40"
            width="40"
          >
            <circle 
              class="track"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
            <circle 
              class="car"
              cx="20" 
              cy="20" 
              r="17.5" 
              pathlength="100" 
              stroke-width="5px" 
              fill="none" 
            />
          </svg>
        </div>
      </>
    );

    
}

export default Authentication