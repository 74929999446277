import React, { useEffect,useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useParams 
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Discord from "./pages/Discord/Discord";
import Product from "./pages/Product/Product";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Orders from "./pages/Dashboard/Dashboard";
import Reward from "./pages/Dashboard/Dashboard";
import Authentication from "./pages/Authentication/Authentication";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Confirm from "./pages/Confirm/Confirm";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Status from "./pages/Status/Status";
import Contact from "./pages/Contact/Contact";
import Website_Background from '/root/client/src/background_image.png';
import LogoColored from '/root/client/src/img/Logo-colored.png';
import useFetch from "/root/client/src/hooks/useFetch.js";
import {useLocation,useNavigate} from "react-router-dom"
import {Helmet} from "react-helmet";



const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Update isOrdersPage when the URL changes
    const hash = window.location.hash;
    if (hash !== '#products') {
      window.scrollTo(0, 0);
    }
   
  }, [location.pathname]);

  useEffect(() => {
    const scrollIntoViewSmooth = async () => {
        const hash = window.location.hash;
        if (hash === '#products') {
            const productsElement = document.getElementById('products');
            if (productsElement) {
                // Scrollen und auf das Ende des Scrollens warten
                await productsElement.scrollIntoView({ behavior: 'smooth' });

                // Entferne #products aus der URL, ohne die Seite neu zu laden
                navigate({ ...location, hash: '' });
              }
          }
      };

      scrollIntoViewSmooth();
  }, [navigate, location]);



  useEffect(() => {
    const image = new Image();
    image.src = {Website_Background};
  }, []);

  
  
  return (
  
      <div className="app" style={{
        backgroundImage: `url(${Website_Background}`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: '50%',
        minHeight: '100vh',
      }}>
    
      <Helmet>
        <title>visuals.gg</title>
        <meta name="description" content="Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"/>
        <meta property="og:description" content="Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"/>
        <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
        <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
        <meta itemprop="name" content="Home | visuals.gg"/>
        <meta property="og:type" content="website"></meta>
      </Helmet>
      <Navbar/>
      <div className="name-text">visuals<span className="name-text-span">.gg</span><br /></div>
      <Outlet />
      <Footer />
    </div>
   
  );
};

const ProductPage = () => {
  const { nameUrl } = useParams();
  const [product, setProduct] = useState(null);
  const { data } = useFetch(`/products?populate=*`);

  useEffect(() => {
    const foundProduct = data.find((item) => item.attributes.nameUrl === nameUrl);
    setProduct(foundProduct);
  }, [data, nameUrl]);
  return (
    <>
        <>
          <Product />
          <Helmet>
          <title>
            {product ? `${product.attributes.name} | visuals.gg` : 'visuals.gg'}
          </title>
          <meta itemprop="name" content={product ? `${product.attributes.name} | visuals.gg` : 'visuals.gg'}/>
            <meta
              name="description"
              content={`Buy ${product ? product.attributes.name : ''} for ${product ? product.attributes.game.data.attributes.name : ''} at visuals.gg for the lowest prices on the market!`}
            />
            <meta
              property="og:description"
              content={`Buy ${product ? product.attributes.name : ''} for ${product ? product.attributes.game.data.attributes.name : ''} at visuals.gg for the lowest prices on the market!`}
            />
            <meta itemprop="name" content={product ? `${product.attributes.name} | visuals.gg` : 'visuals.gg'}/>
            <meta property="image" content={product ? `${"https://api.visuals.gg"+ product.attributes.img.data.attributes.url}` : ''} />
            <meta property="og:image" content={product ? `${"https://api.visuals.gg"+ product.attributes.img.data.attributes.url}` : ''} />
          </Helmet>
        </>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Layout />
      </>
    ),
    children: [
      {
        path: '/',
        element: (
          <>
          <Home/>
          <Helmet>
            <title>Home | visuals.gg</title>
            <meta name="description" content="Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"/>
            <meta property="og:description" content="Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"/>
            <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            <meta itemprop="name" content="Home | visuals.gg"/>
          </Helmet>
          </>
        ),
      },
      {
        path: '/:gameNameUrl/:nameUrl',
        element: <ProductPage/>,
      },
      {
        path: '/login',
        element: (
          <>
          <Login />
          <Helmet>
            <title>Login | visuals.gg</title>
            <meta itemprop="name" content="Login | visuals.gg"/>
            <meta
              name="description"
              property="og:description"
              content="
              Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
            />
            <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
          </Helmet>
          </>
        ),
      },
      {
        path: '/authentication',
        element: (
          <>
          <Authentication />
          <Helmet>
            <title>Authentication | visuals.gg</title>
            <meta itemprop="name" content="Authentication | visuals.gg"/>
            <meta
              name="description"
              property="og:description"
              content="
              Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
            />
            <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
          </Helmet>
          </>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <>
            <Dashboard />
            <Helmet>
              <title>Dashboard | visuals.gg</title>
              <meta itemprop="name" content="Dashboard | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
        children: [
          {
            path: '/dashboard/orders',
            element: (
              <>
                <Orders />
                <Helmet>
                  <title>Orders | visuals.gg</title>
                  <meta itemprop="name" content="Orders | visuals.gg"/>
                  <meta
                    name="description"
                    property="og:description"
                    content="
                    Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
                  />
                  <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
                  <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
                </Helmet>
              </>
            ),
          },
          {
            path: '/dashboard/reward',
            element: (
              <>
                <Reward />
                <Helmet>
                  <title>Free Keys | visuals.gg</title>
                  <meta itemprop="name" content="Free Keys | visuals.gg"/>
                  <meta
                    name="description"
                    property="og:description"
                    content="
                    Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
                  />
                  <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
                  <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
                </Helmet>
              </>
            ),
          },
        ],
      },
      {
        path: '/confirm',
        element: (
          <>
            <Confirm />
            <Helmet>
              <title>Confirm | visuals.gg</title>
              <meta itemprop="name" content="Confirm | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
              <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
      },
      {
        path: '/tos',
        element: (
          <>
            <Terms />
            <Helmet>
              <title>Terms | visuals.gg</title>
              <meta itemprop="name" content="Terms | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
      },
      {
        path: '/policies',
        element: (
          <>
            <Privacy />
            <Helmet>
              <title>Privacy | visuals.gg</title>
              <meta itemprop="name" content="Privacy | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
      },
      {
        path: '/status',
        element: (
          <>
            <Status />
            <Helmet>
              <title>Status | visuals.gg</title>
              <meta itemprop="name" content="Status | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
      },
      {
        path: '/contact',
        element: (
          <>
            <Contact />
            <Helmet>
              <title>Contact | visuals.gg</title>
              <meta itemprop="name" content="Contact | visuals.gg"/>
              <meta
                name="description"
                property="og:description"
                content="
                Enhance your gaming with top-quality cheats at the best prices. Dominate your favorite game, optimize PC performance, achieve god mode – affordably and effectively!"
              />
              <meta property="og:image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/>
            <meta itemprop="image" content="https://api.visuals.gg/uploads/webclip_d1b1d25da9.png"/> 
            </Helmet>
          </>
        ),
      },
      {
        path: '/discord',
        element: (
          <>
            <Discord />
            
          </>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <div>
       <RouterProvider router={router} />
    </div>
  );
}

export default App;
