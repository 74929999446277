import React from 'react';
import useFetch from "../../hooks/useFetch";
import {Link} from "react-router-dom"
const Status = () => {
  const { data } = useFetch(`/games/?populate=*&sort=rank:asc`);

  return (
    <div className="status-container">
      <div className="back-to-home-button-container">
        <Link to="/dashboard" class="back-to-home-button-link w-inline-block">
          <div class="back-to-home-button">
            <div class="back-to-home-button-text"><span class="back-to-home-button-icon"></span>Back</div>
          </div>
        </Link>
      </div>
      <div className="status-heading">
        <div className="status-heading-text">Status Page</div>
      </div>
      <div className="status-flex">
        {data.map((game) => (
          <div key={game.id} className="status-section">
            <div className="status-game-text">{game.attributes.name}</div>
            <div className="status-products">
              {game.attributes.products.data.map((product) => (
                <Link to={`/${product?.attributes.gameNameUrl}/${product?.attributes.nameUrl}`} key={product.id} className={`status-product w-inline-block`}>
                  <div className="status-product-name">{product.attributes.name}</div>
                  <div className="status-product-container">
                    <div className={`${getStatusClass(product.attributes.status)}`}>                     
                      {product.attributes.status}                     
                    </div>
                    {product.attributes.type === 'Key'  && (
                      <>
                      <div className="status-product-and">&</div>

                      <div className={`${getStatusClass(product.attributes.detection)}`}>
                        {product.attributes.detection} 
                      </div>
                      </>
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getStatusClass = (status) => {
  switch (status) {
    case 'Undetected':
      return 'status-undetected';
    case 'Testing':
      return 'status-updating';
    case 'Up to date':
      return 'status-undetected';
    case 'Working':
      return 'status-undetected';
    case 'Offline':
      return 'status-detected';
    case 'Updating':
      return 'status-updating';
    case 'Use at own risk':
      return 'status-use-at-own-risk';
    default:
      return '';
  }
};

export default Status;