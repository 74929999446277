import React from 'react';
import useFetch from "../../hooks/useFetch";

const GamesButtons = ({ onGameSelect, selectedGame }) => {
    const { data, loading, error } = useFetch(`/games?populate=*`);
    return (
        <div class="product-categories">
            <button onClick={() => onGameSelect(null)} class={`category-button w-inline-block ${selectedGame === null ? 'category-button-selected' : ''}`}>
                <div class="category-button-content">
                    <div class={`category-button-text ${selectedGame === null ? 'category-button-text-selected' : ''}`}>All products</div>
                </div>
            </button>
            {data.map(game => (
                <button key={game.attributes.id} onClick={() => onGameSelect(game.id)} class={`category-button w-inline-block category-button-left ${selectedGame === game.id ? 'category-button-selected' : ''}`}>
                    <div class="category-button-content">
                        <div class={`category-button-text ${selectedGame === game.id ? 'category-button-text-selected' : ''}`}>{game.attributes.name}</div>
                    </div>    
                </button>
            ))}
        </div>
    );
};

export default GamesButtons;