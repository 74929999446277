import React from 'react';
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
const Features = () => {

  const { nameUrl } = useParams();
  const { data } = useFetch(`/products?filters[nameUrl][$eq]=${nameUrl}&populate[productFeatures][populate]=*`);
return (
    <>
    {data[0]?.attributes.productFeatures &&
    data[0]?.attributes.productFeatures.visuals &&
    data[0]?.attributes.productFeatures.visuals.length > 0 && (
    <>
    <div class="features">
         
        
    <div class="features-section">
      <div class="features-section-text">This products</div>
    </div>
    <div class="features-heading">
      <div class="features-heading-text">Features</div>
    </div>
    {data.map((product) => (
        <div key={product.id} class="features-cards">
        
            {product.attributes.productFeatures &&
            product.attributes.productFeatures.visuals &&
            product.attributes.productFeatures.visuals.length > 0 && (
            <>
                <div class="features-card">
                <div class="features-card-heading">
                    <div class="features-card-heading-text"><span class="features-card-heading-text-icon-span"> </span> Visuals</div>
                </div>
                <div class="features-card-content">
                    {product.attributes.productFeatures.visuals.map((visual) => (
                    <div class="feature-card-content-text" key={visual.id}>{visual.content}<br/></div>
                    ))}
                </div>
            </div>
            </>
            )}

            {product.attributes.productFeatures &&
            product.attributes.productFeatures.aimbot &&
            product.attributes.productFeatures.aimbot.length > 0 && (
            <>
            <div class="features-card">
                <div class="features-card-heading">
                    <div class="features-card-heading-text"><span class="features-card-heading-text-icon-span"> </span> Aim</div>
                </div>
                <div class="features-card-content">
                    {product.attributes.productFeatures.aimbot.map((aimbot) => (
                    <div class="feature-card-content-text" key={aimbot.id}>{aimbot.content}<br/></div>
                    ))}
                </div>
            </div>
            </>
            )}

            
            
        {product.attributes.productFeatures &&
        product.attributes.productFeatures.misc &&
        product.attributes.productFeatures.misc.length > 0 && (
        <>
        <div class="features-card"> 
            <div class="features-card-heading">
                <div class="features-card-heading-text"><span class="features-card-heading-text-icon-span"> </span> Misc</div>
            </div>
            <div class="features-card-content">
                {product.attributes.productFeatures.misc.map((misc) => (
                <div class="feature-card-content-text" key={misc.id}>{misc.content}<br/></div>
                ))}
            </div>
        </div>
        </>
        )}
    </div>
        
    ))}
    </div>
    </>
    )}
    
    </>
);
 
};

export default Features;