import React from 'react'
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import ProductPricing from '../../components/ProductPricing/ProductPricing';
import Features from '../../components/features/features';
import { useDispatch } from "react-redux";
import { useState,useEffect } from "react";
import { addToCart } from "../../redux/cartReducer";
import {loadStripe} from '@stripe/stripe-js';
import { makeRequest } from '../../makeRequest';
import { useAuthContext } from "../../context/AuthContext";
import { toast,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom"
import Paysafecard_logo from '/root/client/src/img/Paysafecard_logo.svg.png';
import Apple_Pay_logo from '/root/client/src/img/800px-Apple_Pay_logo.svg.png';
import Mastercard_logo from '/root/client/src/img/Mastercard_2019_logo.svg.png';
import LightCoin_Logo from '/root/client/src/img/3305886.png';
import Bitcoin_logo from '/root/client/src/img/Bitcoin.svg.png';
import Google_Pay_Logo from '/root/client/src/img/Google_Pay_Logo.svg.png';
import PayPal_Logo from '/root/client/src/img/PayPal_Logo_Icon_2014.svg.png';
import Visa_logo from '/root/client/src/img/Visa_2021.png';
import ethereum_logo from '/root/client/src/img/ethereum-logo-EC6CDBA45B-seeklogo.com.png';

const Product = () => {
  const { nameUrl } = useParams();
  
  const dispatch = useDispatch();
  const qs = require('qs');
  const queryURL = qs.stringify({
    filters: {
      nameUrl: {
        $eq: nameUrl,
      },
    },
    populate: {
      duration: {
        populate: true,
      },
      productFeatures: {
        populate: true,
      },
      SystemRequirements: {
        populate: true,
      },
      period: {
        populate: true,
      },
      img: {
        populate: true,
      },
      type: {
        populate: true,
      },
      game: {
        populate: true,
      },
      payment: {
        populate: true,
      },
      status: {
        populate: true,
      },
      instruktions: {
        populate: true,
      },
      software: {
        populate: true,
      },
    },
  });
  const { data, loading } = useFetch(`/products?${queryURL}`);
  const restData = data[0];
  const productsActive = restData?.attributes?.active;

  console.log(data)

  let period;
  let init_duration;
  let init_price;
  let init_id;

  if (typeof restData === 'undefined') {
    init_duration = 'Undefined';
    init_id = 'Undefined';
    init_price = '';
  }else{ 
    if (typeof restData.attributes === 'undefined') {
      init_duration = 'Undefined';
      init_id = 'Undefined';
      init_price = '';
    } else {
      init_id = data[0].id;
      period = restData.attributes.duration || [];
      init_duration = period[0]?.durationtime || 'Undefined';
      init_price = period[0]?.price || '';
    }
  }
  const [quantity, setQuantity] = useState(1);
  const [duration, setduration] = useState([]);
  const [counter, setCounter] = useState();
  const [Price, setPrice] = useState([]);

  
  const [updatedItems, setUpdatedItems] = useState([]);
  const updatekeycounter = async () => {
    try {
      const response = await makeRequest.put(`/stockCounter`, { id: init_id });
      const updatedItems = response.data;
      setUpdatedItems(updatedItems);
  
      const firstAvailableItem = updatedItems.find((item) => item.keyCount !== 0);
  
      if (firstAvailableItem) {
        setduration(firstAvailableItem.durationtime);
        setPrice(firstAvailableItem.price);
      }
      
    } catch (error) {
      console.log('Fehler', error);
    
    }
  };

  useEffect(() => {
    if (init_id !== "Undefined") {
      updatekeycounter();
    }
  }, [init_id]);

  

  const [total, setTotal] = useState((total) => setPrice(Price));

  const { user, setUser } = useAuthContext();
  const [email, setEmail] = useState([]);
  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (products.length > 0) {
      handleBuyNow();
    }
  }, [products]);

  const paymentAttributes = restData?.attributes?.payment?.data?.attributes;
  const isCardEnabled = paymentAttributes?.Card ;
  const isCryptoEnabled = paymentAttributes?.Crypto ;
  const isPaypalEnabled = paymentAttributes?.Paypal ;
  const isPaysafeEnabled = paymentAttributes?.Paysafe ;
  
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  useEffect(() => {

        if (isCardEnabled) {
          setSelectedPaymentMethod('card');
        } else if (isPaypalEnabled) {
          setSelectedPaymentMethod('paypal');
        } else if (isCryptoEnabled) {
          setSelectedPaymentMethod('crypto');
        } else if (isPaysafeEnabled) {
          setSelectedPaymentMethod('paysafe');
        }
    
  }, [isCardEnabled]);
  // Handler, um die ausgewählte Zahlungsmethode festzulegen
  const handlePaymentMethodChange = (method) => {
   setSelectedPaymentMethod(method);
 };
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const handleBuyNow = async () => {
    try {
      // Suchen der ausgewählten Dauer (duration) in den bereits vorhandenen productInfo-Daten
      const response = await makeRequest.put(`/stockCounter`, { id: init_id });
      const updatedItems = response.data;
      const matchingItem = updatedItems.find((item) => item.durationtime === duration);
      if (matchingItem.keyCount > 0) {
        // Hier wird die Produktdaten erneut abgerufen, um sicherzustellen, dass sie aktuell sind.
            
                  // Hier kannst du den Rest deines Codes für die Bestellung einfügen
                  const stripe = await stripePromise;
                  setIsLoading(true);
  
                 

                    if (!user) {
                      setIsLoading(true);
                      if (products && products.length > 0) {
                        localStorage.setItem('redirectToCheckout', 'true');
                        localStorage.setItem('selectedPaymentMethod', `${selectedPaymentMethod}`);
                        localStorage.setItem('handelType', `buyNow`);
                        localStorage.setItem('products', JSON.stringify(products));
                        window.location.replace(`${process.env.REACT_APP_URL}login`);
                      }
                      setIsLoading(false);
                      return;
                    }
                    
                    if(products.length > 0){
                      localStorage.setItem('handelType', `buyNow`);
                      localStorage.setItem('products', JSON.stringify(products));
                      const res = await makeRequest.post("/orders", {
                        products,
                        email: email,
                        selectedPaymentMethod: selectedPaymentMethod,
                        user,
                        currentUrl: window.location.href,
                      });
    
                      await stripe.redirectToCheckout({
                        sessionId: res.data.stripeSession.id,
                      });
                    }
                    
                    

                  
                } else {
                  // Hier kannst du eine Benachrichtigung anzeigen, dass keine Schlüssel verfügbar sind
                  toast.error(`Out of stock`, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    toastId : "2",
                    transition: Slide,
                    });
                  }

    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  let stockCounter = 0; // Standardwert für den Stock Counter
if (updatedItems) {
  const selectedDurationItem = updatedItems.find((item) => item.durationtime === duration);
  if (selectedDurationItem) {
    stockCounter = selectedDurationItem.keyCount;
  }
}
  let stockStatus = "In Stock"; // Standardwert

  if (stockCounter === 0 || !productsActive) {
    stockStatus = "Out of Stock";
  } else if (stockCounter < 5) {
    stockStatus = "Fast Ausverkauft";
  }

  const [loadprod, setLoadprod] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  
  return (
    <>
        {loading ? (
          <>
         <div class="authentication-flex space">
         <svg
           class="container"
           viewBox="0 0 40 40"
           height="40"
           width="40"
         >
           <circle 
             class="track"
             cx="20" 
             cy="20" 
             r="17.5" 
             pathlength="100" 
             stroke-width="5px" 
             fill="none" 
           />
           <circle 
             class="car"
             cx="20" 
             cy="20" 
             r="17.5" 
             pathlength="100" 
             stroke-width="5px" 
             fill="none" 
           />
         </svg>
       </div>
       <div class="push-product"></div>
       </>
        ) : ( 
          <>
        <div class="hero">
          <div class="back-to-home-button-container">
            <Link to="/#products" class="back-to-home-button-link w-inline-block">
              <div class="back-to-home-button">
                <div class="back-to-home-button-text"><span class="back-to-home-button-icon"></span> Store</div>
              </div>
            </Link>
          </div>
          <div class="product-name">
            <div class="product-name-text">{restData?.attributes?.name}</div>
            <div class="product-name-game">
              <div class="product-name-game-text">{restData?.attributes?.gameName}</div>
            </div>
          </div>

          <div class="product-main">
        <img
          src={process.env.REACT_APP_UPLOAD_URL + restData?.attributes?.img?.data?.attributes?.url}
          alt=""
          class="product-image"
        />


        <div class="product-variations-payment">
        <ProductPricing data={restData} stockStatus={stockStatus} duration={duration} Price={Price} setduration={setduration} setPrice={setPrice} />



          
          <div class="payment-options">
              <button 
                class={`payment-option  ${selectedPaymentMethod === 'card' ? 'payment-option-selected' : ''} ${!isCardEnabled ? 'payment-option-disabled' : ''}`}
                onClick={() => handlePaymentMethodChange('card')}
                disabled={!isCardEnabled}
              >
                <div class="payment-option-text">
        {!isHovering && (
          <>
          <span className="hidden-options2">
            Card, Apple/Google Pay,  <br/>iDEAL, EPS & 
          </span>
          <span 
          className="more-options"
          onMouseEnter={() => setIsHovering(true)}
        >
          more
        </span>
          
          </>
        )}
      
        {isHovering && (
        <>
          <span className="hidden-options2" >
          Bancontact, giropay,<br/> Klarna, P24 & 
          </span>
          <span 
            className="more-options"
            onMouseLeave={() => setIsHovering(false)}
          >
            more
          </span>
        </>
        )}
        
       <span class="via-gateway-span">via </span><span class="stripe-span">Stripe</span></div>
                <div class="payment-options-icons">
                  <div class="payment-method-icon-container"><img src={Mastercard_logo} loading="lazy" width="27" sizes="(max-width: 767px) 100vw, (max-width: 1279px) 27px, (max-width: 1439px) 2vw, 27px" alt=""class="payment-method-icon"/></div>
                  <div class="payment-method-icon-container"><img src={Visa_logo} loading="lazy" width="36" sizes="(max-width: 767px) 100vw, (max-width: 1279px) 36px, (max-width: 1439px) 3vw, 36px" alt="" class="payment-method-icon"/></div>
                  <div class="payment-method-icon-container"><img src={Apple_Pay_logo} loading="lazy" width="36" sizes="(max-width: 767px) 100vw, (max-width: 1279px) 36px, (max-width: 1439px) 3vw, 36px" alt=""  class="payment-method-icon apple-pay-margin"/></div>
                  <div class="payment-method-icon-container"><img src={Google_Pay_Logo} loading="lazy" width="36" sizes="(max-width: 767px) 100vw, (max-width: 1279px) 36px, (max-width: 1439px) 3vw, 36px" alt="" class="payment-method-icon"/></div>
                  <div class="payment-method-icon-container">
                    <div class="bank-transfer-icon"></div>
                  </div>
                </div>
              </button>


            <button 
              class={`payment-option  ${selectedPaymentMethod === 'paypal' ? 'payment-option-selected' : ''} ${!isPaypalEnabled ? 'payment-option-disabled' : ''}`}
              onClick={() => handlePaymentMethodChange('paypal')}
              disabled={!isPaypalEnabled}
            >
                <div class="payment-option-text">PayPal <span class="via-gateway-span">via </span><span class="stripe-span">Stripe + 0.50€ fee</span></div>
                <div class="payment-options-icons">
                  <div class="payment-method-icon-container"><img src={PayPal_Logo} loading="lazy" width="20" sizes="(max-width: 767px) 100vw, (max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw" alt="" class="payment-method-icon"/></div>
                </div>
            </button>

            <button
              class={`payment-option  ${selectedPaymentMethod === 'crypto' ? 'payment-option-selected' : ''} ${!isCryptoEnabled ? 'payment-option-disabled' : ''}`}
              onClick={() => handlePaymentMethodChange('crypto')}
              disabled={!isCryptoEnabled}
            >
                <div class="payment-option-text">Crypto <span class="via-gateway-span">via </span><span class="stripe-span">Discord</span></div>
                <div class="payment-options-icons">
                  <div class="payment-method-icon-container"><img src={Bitcoin_logo} loading="lazy" width="20" sizes="(max-width: 767px) 100vw, (max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw" alt="" class="payment-method-icon"/></div>
                  <div class="payment-method-icon-container"><img src={ethereum_logo} loading="lazy" width="20" alt="" class="payment-method-icon"/></div>
                  <div class="payment-method-icon-container"><img src={LightCoin_Logo} loading="lazy" width="20" sizes="(max-width: 767px) 100vw, (max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw" alt=""  class="payment-method-icon"/></div>
                </div>
            </button>

            <button
              class={`payment-option  ${selectedPaymentMethod === 'paysafe' ? 'payment-option-selected' : ''} ${!isPaysafeEnabled ? 'payment-option-disabled' : ''}`}
              onClick={() => handlePaymentMethodChange('paysafe')}
              disabled={!isPaysafeEnabled}
            >
                <div class="payment-option-text">PaySafeCard <span class="via-gateway-span">via </span><span class="stripe-span">Discord</span></div>
                <div class="payment-options-icons">
                  <div class="payment-method-icon-container payment-method-icon-container-special"><img src={Paysafecard_logo} loading="lazy" width="90" sizes="(max-width: 767px) 100vw, 90px" alt="" class="payment-method-icon"/></div>
                </div>
            </button>
          </div>


          <div class="purchase-buttons">
            <div class="buynow-buttons-cont">
              <button
                class="buy-now-button-link w-inline-block buy-now-button"
                type="button"
                disabled={stockCounter === 0 || !productsActive}
                onClick={async () => {
                  if (selectedPaymentMethod === 'card') {
                    // Hier wird die "handleBuyNow" Funktion aufgerufen
                    const productInfo = {
                      id: restData.id,
                      name: restData.attributes.name,
                      nameUrl: restData.attributes.nameUrl,
                      gameName: restData.attributes.gameName,
                      gameNameUrl: restData.attributes.gameNameUrl,
                      img: restData.attributes.img.data.attributes.url,
                      type: restData.attributes.type,
                      software: restData.attributes.software,
                      quantity,
                      duration,
                      Price,
                    };
                    setProducts([productInfo]);
                    handleBuyNow();
                  } else if (selectedPaymentMethod === 'paypal') {
                    // Hier können Sie den Link für Paysafe einfügen
                    const productInfo = {
                      id: restData.id,
                      name: restData.attributes.name,
                      nameUrl: restData.attributes.nameUrl,
                      gameName: restData.attributes.gameName,
                      gameNameUrl: restData.attributes.gameNameUrl,
                      img: restData.attributes.img.data.attributes.url,
                      software: restData.attributes.software,
                      type: restData.attributes.type,
                      quantity,
                      duration,
                      Price,
                    };
                    setProducts([productInfo]);
                    handleBuyNow();
                  } else if (selectedPaymentMethod === 'paysafe') {
                    // Hier können Sie den Link für Paysafe einfügen
                    setIsLoading(true);
                    window.open('https://discord.gg/RYXKgMKD55', '_blank');
                    setIsLoading(false);
                  } else if (selectedPaymentMethod === 'crypto') {
                    // Hier können Sie den Link für Crypto einfügen
                    setIsLoading(true);
                    window.open('https://discord.gg/S9KhXeZn9e', '_blank');
                    setIsLoading(false);
                  }
                }}
              >
                  <div class="buy-now-button-text">Buy now</div>
              </button>
              <div class="agreeTerms">By purchasing you agree to our <Link to="/tos" class="agreeTerms-link"> Terms of Service </Link></div>
            </div>

            <button
              class={`add-to-cart-button-link w-inline-block add-to-cart-button ${selectedPaymentMethod !== 'card' && selectedPaymentMethod !== 'paypal' ? 'add-to-cart-button-disabled' : ''}`}
              type="button"
              disabled={stockCounter === 0 || (selectedPaymentMethod !== 'card' && selectedPaymentMethod !== 'paypal') || !productsActive}
              onClick={async () => {
                if (selectedPaymentMethod === 'card' || selectedPaymentMethod === 'paypal') {
                  try {
                    // Hier wird die Anfrage an den Server gemacht
                    const response = await makeRequest.put(`/updatekeycounter/${restData.id}`, {
                      duration: duration,
                    });

                    // Wenn die Anfrage erfolgreich ist, füge das Produkt zum Warenkorb hinzu
                    dispatch(
                      addToCart({
                        id: restData.id,
                        name: restData.attributes.name,
                        nameUrl: restData.attributes.nameUrl,
                        gameName: restData.attributes.gameName,
                        gameNameUrl: restData.attributes.gameNameUrl,
                        img: restData.attributes.img.data.attributes.url,
                        software: restData.attributes.software,
                        type: restData.attributes.type,
                        quantity,
                        duration,
                        Price,
                        keyCount: response.data.keyCount, // Füge den keyCount hinzu
                      })
                    );
                    
                  } catch (error) {
                    
                  }
                } else {
                  // Zeige eine Benachrichtigung an oder deaktiviere den Button
                  toast.error('Payment Method Not Supported', {
                    position: "bottom-center",
                    // ... (andere Toast-Einstellungen)
                  });
                }
              }}
            >
                <div class="add-to-cart-icon"></div>
            </button>
          </div>
         
        </div>
      </div>
    </div>
        <div class="scroller scroller-product">
          <div class="scroller-icon"></div>
        </div>
          
        {restData?.attributes?.video && (
          <div class="showcase">
            <div class="showcase-section">
              <div class="showcase-section-text">YouTube</div>
            </div>
            <div class="showcase-heading">
              <div class="showcase-heading-text">Showcase</div>
            </div>
            <iframe src={restData?.attributes?.video} title="YouTube video player" frameborder="0" allowfullscreen="allowfullscreen" class="showcase-embed"></iframe>
          </div>
        )}
        <Features/>
        <div class="why-us">
          <div class="system-section">
            <div class="system-section-text">This product supports</div>
          </div>
          <div class="system-heading">
            <div class="system-heading-text">System requirements</div>
          </div>
          <div class="system-cards">
            {data[0]?.attributes.SystemRequirements[0].__component === "useperiod.system-acc" && ( 
              <>
              <div id="w-node-_268ed7a1-80b0-4ca5-9306-db09af6f1d63-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Email</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].email}</div>
                </div>
              </div>

              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958d1-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Password</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].password}</div>
                </div>
              </div>

              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958db-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Username</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].username}</div>
                </div>
              </div>

              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958c7-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon other-font"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Operating System</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].operatingSystem}</div>
                </div>
              </div>
              
              <div id="w-node-a91d73cf-84a8-f92d-6131-8e025c8cc200-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Access</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].lifetimeAccess}</div>
                </div>
              </div>
              
              <div id="w-node-_30443aca-e7d1-fe95-7a9b-61f9f0e5f6d0-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Platform</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].platform}</div>
                </div>
              </div>
              </>
            )}
            {data[0]?.attributes.SystemRequirements[0].__component === "useperiod.system-cheat" && ( 
              <>
              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958c7-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon other-font"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Operating System</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].operatingSystem}</div>
                </div>
              </div>
              
              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958d1-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Processors</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].processors}</div>
                </div>
              </div>
              <div id="w-node-db9f7bf8-9d8e-7755-94bf-8c300ac958db-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Anti-Cheat</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].antiCheat}</div>
                </div>
              </div>
              <div id="w-node-a91d73cf-84a8-f92d-6131-8e025c8cc200-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Game Mode</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].gameMode}</div>
                </div>
              </div>
              <div id="w-node-_268ed7a1-80b0-4ca5-9306-db09af6f1d63-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Spoofer included</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].spoofer}</div>
                </div>
              </div>
              <div id="w-node-_30443aca-e7d1-fe95-7a9b-61f9f0e5f6d0-9f45aeab" class="system-card">
                <div class="system-icon-background">
                  <div class="system-card-icon"></div>
                </div>
                <div class="system-text-container">
                  <div class="system-card-description-text">Platform</div>
                  <div class="system-card-text">{restData?.attributes?.SystemRequirements[0].platform}</div>
                </div>
              </div>
              </>
            )}
          </div>

        </div> 
        </>
      )}
    </>
  );
}

export default Product;