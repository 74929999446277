import React, { useState, useEffect } from 'react';
import { makeRequest } from '../../makeRequest';

function ProductPricing({ data, setduration, setPrice, duration, Price, stockStatus }) {
  const [updatedItems, setUpdatedItems] = useState([]); // Neu hinzugefügte Zeile
  const [loading, setLoading] = useState(true);
  let period;
  let id;
  if (data && data.attributes) {
    period = data.attributes.duration;
    id = data.id;
  } else {
    period = [];
    id = 'Undefined';
  }

  const [active, setActive] = useState(null);

  const getUpdatedItem = (duration) => {
    return updatedItems.find((item) => item.durationtime === duration);
  };

  const updatekeycounter = async () => {
    try {
      const response = await makeRequest.put(`/stockCounter`, { id: id });
      const updatedItems = response.data;
      setUpdatedItems(updatedItems);
  
      const firstAvailableItem = updatedItems.find((item) => item.keyCount !== 0);
  
      if (firstAvailableItem) {
        setduration(firstAvailableItem.durationtime);
        setPrice(firstAvailableItem.price);
        setActive(firstAvailableItem);
      }
      setLoading(false);
    } catch (error) {
      console.log('Fehler', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== "Undefined") {
      updatekeycounter();
    }
  }, [period]);

  return (
    <>
      {loading ? (
          <>
         <div class="authentication-flex space">
         <svg
           class="container"
           viewBox="0 0 40 40"
           height="40"
           width="40"
         >
           <circle 
             class="track"
             cx="20" 
             cy="20" 
             r="17.5" 
             pathlength="100" 
             stroke-width="5px" 
             fill="none" 
           />
           <circle 
             class="car"
             cx="20" 
             cy="20" 
             r="17.5" 
             pathlength="100" 
             stroke-width="5px" 
             fill="none" 
           />
         </svg>
       </div>
       <div class="push-product"></div>
       </>
        ) : (  
          <div className="product-variations-container">
          {period.map((item) => (
            <div className="product-variation-container" key={item.id}>
              <button 
                onClick={() => {
                  const updatedItem = getUpdatedItem(item.durationtime);
                  setduration(updatedItem.durationtime);
                  setPrice(updatedItem.price);
                  setActive(updatedItem);
                }}
                type="button"
                className={`product-variation-link w-inline-block`}
                disabled={!getUpdatedItem(item.durationtime) || getUpdatedItem(item.durationtime).keyCount === 0 || !data.attributes.active}
              >
                <div className={`product-variation ${active && active.id === item.id ? 'product-selected' : (getUpdatedItem(item.durationtime) && getUpdatedItem(item.durationtime).keyCount === 0) || !data.attributes.active ? 'product-disabled' : ''}`}>
                  <div className="product-variation-text">{item.durationtime}</div>
                  <div className="product-variation-price">{getUpdatedItem(item.durationtime) ? getUpdatedItem(item.durationtime).price : 0}€</div>
                </div>
              </button>
              {(getUpdatedItem(item.durationtime) && getUpdatedItem(item.durationtime).keyCount === 0) || !data.attributes.active ? (
                    <span className="stock-counter-text">Out of stock</span>
                  ) : null}
            </div>
          ))}
        </div>
        ) }
     
    </>
  );
}

export default ProductPricing;