import React, { useEffect, useState } from 'react';
import useFetch from "../../hooks/useFetch";
import { saveAs } from "file-saver";
import axios from "axios";

const Loader = ({ orderdata }) => {
  const [loader, setLoader] = useState(null);
  const [tutorial, setTutorial] = useState(null);
  const [instruktion, setInstruktion] = useState(null);
  const { data: products, loading } = useFetch(`/products/?populate=*`);

  useEffect(() => {
    if (!loading && products && orderdata) {
      const product = products.find((p) => p.id === orderdata.id);

      if (product && product.attributes) {
        if (product.attributes.software && product.attributes.software.data) {
          setLoader(product.attributes.software.data.attributes.url);
        } else if (product.attributes.softwareUrl) {
          setLoader(product.attributes.softwareUrl);
        }

        setTutorial(product.attributes.tutorial);

        if (
          product.attributes.instruktions &&
          product.attributes.instruktions.data &&
          product.attributes.instruktions.data.attributes
        ) {
          setInstruktion(product.attributes.instruktions.data.attributes.url);
        }
      }
    }
  }, [loading, products, orderdata]);

  const isLoaderEmpty = !loader;
  const isTutorialEmpty = !tutorial;
  const isInstruktionEmpty = !instruktion;
  
  const handleDownload = async (fileUrl) => {
    try {
     
      const response = await axios.get(fileUrl, {
        responseType: "blob", 
      });

      
      const blob = new Blob([response.data], { type: response.data.type });
      saveAs(blob, "Instructions"); 
    } catch (error) {
 
      console.error(error);
    }
  };

  return (
    <>
      {!loading && (
        <>
        {!isTutorialEmpty && (
          <div class="tutorial-download">
            <div class="tutorial-download-heading">Tutorial</div>
              <a href={tutorial} target="_blank" rel="noopener noreferrer" class="tutorial-download-button w-inline-block">
                <div class="tutorial-download-button-text">WATCH</div>
              </a>
          </div>
        )}
        {!isInstruktionEmpty && (
          <div class="instructions-download">
            <div class="instructions-download-heading">Instructions</div>
            <a onClick={() => handleDownload(process.env.REACT_APP_UPLOAD_URL + instruktion)} class="instructions-download-button w-inline-block">
                <div class="instructions-download-button-text">DOWNLOAD</div>
              </a>
          </div>
        )}
        {!isLoaderEmpty && (
        <div class="loader-download">
          <div class="loader-download-heading">Loader</div>
            <a href={loader.startsWith("http") ? loader : process.env.REACT_APP_UPLOAD_URL + loader} target="_blank" class="loader-download-button w-inline-block">
              <div class="loader-download-button-text">DOWNLOAD</div>
            </a>
        </div>
        )}
        </>
      )}
    </>
  );
};

export default Loader;