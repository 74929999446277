import React, { useState,useEffect } from 'react';
import {useSelector} from "react-redux"
import {Link,useLocation} from "react-router-dom"
import { useDispatch } from "react-redux";
import { itemQuanMinus,itemQuanPlus} from "../../redux/cartReducer";
import useFetch from "../../hooks/useFetch";
import {loadStripe} from '@stripe/stripe-js';
import { makeRequest } from '../../makeRequest';
import { useAuthContext } from "../../context/AuthContext";
import { removeToken,setToken,getToken } from "../../helpers";
import axios from 'axios';
import Modal from 'react-modal';
import { toast,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mastercard_logo from '/root/client/src/img/Mastercard_2019_logo.svg.png';
import Visa_logo from '/root/client/src/img/Visa_2021.png';
import PayPal_Logo from '/root/client/src/img/PayPal_Logo_Icon_2014.svg.png';
import LogoColored from '/root/client/src/img/Logo-colored.png';

const Navbar = () => {
    const qs = require('qs');
  const queryURL = qs.stringify({
    populate: {
      duration: {
        populate: true,
      },
      productFeatures: {
        populate: true,
      },
      SystemRequirements: {
        populate: true,
      },
      period: {
        populate: true,
      },
      img: {
        populate: true,
      },
      type: {
        populate: true,
      },
      game: {
        populate: true,
      },
      payment: {
        populate: true,
      },
      status: {
        populate: true,
      },
      instruktions: {
        populate: true,
      },
      software: {
        populate: true,
      },
    },
  });
  const { data, loading } = useFetch(`/products?${queryURL}`);
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  
  
  /*useEffect(() => {
    
    const tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/qwuywbv8npfjjuiavexr77x9rdo0yfw9.js';
    tidioScript.async = true;
    document.head.appendChild(tidioScript);

    
  }, []); */

  const totalPrice = () => {
    let total = 0;
    products.map((item) => {
      total += item.quantity * item.Price;
    });
    return total.toFixed(2);
  };

  const getTotalQuantity = () => {
    let total = 0
    products.forEach(item => {
      total += item.quantity
    })
    return total
  }
  const [quantity, setQuantity] = useState(1);
  const [CartOpen, setCartOpen] = useState(false);
  const [headermenucloseOpen, setheadermenucloseOpen] = useState(false);
  const [headermenubackgroundOpen, setheadermenubackgroundOpen] = useState(false);
  
  const toggleCart = () => {
    setCartOpen(!CartOpen);
    setheadermenucloseOpen(!headermenucloseOpen);
    setheadermenubackgroundOpen(!headermenubackgroundOpen);
  };

  const handleClose = () => setCartOpen(false) &  setheadermenucloseOpen(false) & setheadermenubackgroundOpen(false);
  const { user,setUser  } = useAuthContext();
  const [email, setEmail] = useState([]);
  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);


  const [isLoading, setIsLoading] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const handlePayment = async (paymentMethod) => {
    try {
      setIsLoading(true);
  
      const notAvailableProducts = []; 
  
      for (const product of products) {
       
        const response = await makeRequest.put(`/stockCounter`, { id: product.id });
        const productInData = response.data;
  
        
        if (productInData) {
          let productOutOfStock = false;
  
          for (const durationItem of productInData) {
            if (durationItem.durationtime === product.duration) {
              const counter = durationItem.keyCount;
              const purchasedQuantity = product.quantity || 1; 
  
              if (counter <= 0) {
                notAvailableProducts.push({
                  name: `${product.gameName} ${product.name} ${product.duration} Out of stock`,
                });
                productOutOfStock = true;
                break;
              }
  
              if (counter < purchasedQuantity) {
                const remainingKeysInfo = counter > 0 ? `(only ${counter} left)` : '';
                notAvailableProducts.push({
                  name: `${product.gameName} ${product.name} ${product.duration} ${remainingKeysInfo}`,
                });
                productOutOfStock = true;
                break;
              }
            }
          }
  
          if (productOutOfStock) {
            continue;
          }
        }
  
      }
  
      
      if (notAvailableProducts.length > 0) {
        const notAvailableProductNames = notAvailableProducts.map(
          (product) => `${product.name}`
        ).join(", ");
        toast.error(`${notAvailableProductNames}`, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          toastId: "3",
          transition: Slide,
        });
  
        
        return;
      }
  
      
      if (!user) {
        localStorage.setItem('redirectToCheckout', 'true');
        localStorage.setItem('selectedPaymentMethod', `${paymentMethod}`);
        localStorage.setItem('handelType', `buyCart`);
        window.location.replace(`${process.env.REACT_APP_URL}login`);
        return;
      }

      localStorage.setItem('handelType', `buyCart`);
      
      const stripe = await stripePromise;
  
      const res = await makeRequest.post("/orders", {
        products,
        email,
        user,
        selectedPaymentMethod: paymentMethod,
        currentUrl: window.location.href,
      });
  
      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });
    } catch (error) {
      console.error('Fehler', error);
    } finally {
      setIsLoading(false);
    }
  };

  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const handelType = localStorage.getItem('handelType');
  const storedProducts = JSON.parse(localStorage.getItem('products')) || []; // Produkte aus dem Local Storage holen
  const productsFromRedux = useSelector((state) => state.cart.products);
  const [productsOrder, setProductsOrder] = useState(handelType === "buyNow" ? storedProducts : productsFromRedux);
  const queryParameters = new URLSearchParams(window.location.search)
  const user_id = queryParameters.get("user_id")
  const releaseReservedKeys = async () => {
    try {
      
      const response = await makeRequest.post(`/orders/resetKeysOrder`, {
        user: user_id,
        products: productsOrder,
      });
    } catch (error) {
      console.error('Error releasing reserved keys:', error);
      throw error; 
    }
  };

  if (searchParams.get('success') === 'false') {
    releaseReservedKeys();
    const currentUrl = window.location.href;
    const parts = currentUrl.split('?');
    const baseUrl = `${parts[0]}?cancel`;
    const updatedUrl = baseUrl;
    localStorage.removeItem('redirectToCheckout');
    window.history.replaceState({}, document.title, updatedUrl);

  }
  if (user && user.data === null) {
    removeToken();
    setUser();
  }
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const getRefreshToken = async () =>  {
    const data = {
      refreshToken: getToken(),
    };
    const options = {
      "Access-Control-Allow-Credentials": true,
      withCredentials: true,
    };
    axios
        .post("https://api.visuals.gg/api/token/refresh",
        data, options, {
        })
      .then(response => {
        // Handle success.
        setToken(response.data.jwt);
        setIsTokenExpired(false);
        closeModal();
      })
      .catch(error => {
        // Handle error.
        console.log('An error occurred:', error.response);
      });
  };
  
  const refreshCountdown = () => {
    setTimeout(() => {
      const token = getToken();
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        if (payload.exp < currentTime) {
          removeToken();
          setUser();
          closeModal();
        }
      }
    }, 300000);
  };

  const checkTokenExpiration = () => {
    const token = getToken();
    if (user && token && !isTokenExpired) {
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        if (payload.exp < currentTime) {
          setIsTokenExpired(true);
          openModal();
          refreshCountdown();
        }
      }
    }
  };
  
  useEffect(() => {
    checkTokenExpiration();
  }, [user, isTokenExpired]);
  
  useEffect(() => {
    if (user && !isTokenExpired) {
      const interval = setInterval(() => {
        checkTokenExpiration();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [user, isTokenExpired]);

  const handleLogout = () => {
    removeToken();
    setUser();
    closeModal();
  };

  const paymentAttributes = data[0]?.attributes?.payment?.data?.attributes;
  const isCardEnabled = paymentAttributes?.Card || false;
  const isPaypalEnabled = paymentAttributes?.Paypal || false;

  const location = useLocation();
  const [isOrdersPage, setIsOrdersPage] = useState(location.pathname.includes('/dashboard'));

  useEffect(() => {
    
    setIsOrdersPage(location.pathname.includes('/dashboard'));
  }, [location.pathname]);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
    
    <a href="https://discord.gg/z7CJcyMXWm" target='_blank' aria-current="page" class="discord-tap"></a>
    
    <div  class="header">
    
      <Link to="" aria-current="page" class="logo-name-link w-inline-block w--current">
        <div class="logo-name-container">
          <div class="logo-container"><img src={LogoColored} loading="lazy" width="40" alt="" class="logo-image"/></div>
        </div>
      </Link>
      <div class="backSoon">Due to maintenance, we will be back in the next 24 hours. For more info, join our Discord server.</div>
      <div class={`login-cart-container ${isOrdersPage ? 'login-cart-container-dashboard' : ''}`}>
        <a class="cart-link-container w-inline-block" onClick={toggleCart}>
          <div class="cart-container">
            <div class="cart-icon"></div>
            <div class="cart-number">{getTotalQuantity() || 0}</div>
          </div>
        </a>
        {user ? (
          <>
          {isOrdersPage ? (
            <Link to="/" class="login-link-container w-inline-block">
              <div class="login-container">
                <div class="logout-icon"></div>
              </div>
            </Link>
           ) : ( 
            <Link to="/dashboard" class="login-link-container w-inline-block">
              <div class="login-container">
                <div class="login-icon"></div>
              </div>
            </Link>
          )}
          </>
        ) : ( 
          <>
          <Link to="/login" class="login-link-container w-inline-block">
            <div class="login-container">
              <div class="login-icon"></div>
            </div>
          </Link>
        </>
         )}
      </div>
      
      {user ? "" : (
        <>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleLogout}
          shouldCloseOnOverlayClick={true}
          style={{
            overlay: {
              zIndex: "999",
              backgroundColor: "rgb(24 24 26 / 44%)",
            },
            content: {
              width: '400px',
              height: '300px',
              backgroundColor: '#202124',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              border: '1px solid #35373b',
              borderRadius: '12px',
              padding: '10px',
              boxShadow: '0 4px 5px rgba(0, 0, 0, .2)',
            },
          }}
        >
          <div class="refresh-popup-background"></div>
          <div class="refresh-popup-x-flex">
            <a class="refresh-popup-x-icon w-inline-block" onClick={handleLogout}>
              <div class="refresh-popup-x-icon-text"></div>
            </a>
          </div>
          <div class="refresh-popup-content-flex">
            <div class="refresh-popup-heading">You seem to be inactive. Do you still want to stay logged in?</div>
            <div class="refresh-popup-buttons-flex">
              <button onClick={handleLogout} class="refresh-popup-logout-button w-inline-block">
                <div class="refresh-popup-logout-button-text">Logout</div>
              </button>
              <button onClick={getRefreshToken} class="refresh-popup-stay-button w-inline-block">
                <div class="refresh-popup-stay-button-text">Stay logged in</div>
              </button>
            </div>
          </div>
        </Modal>
        </>
      )}
    </div>
    
    <div className={`headermenuclose ${headermenucloseOpen ? 'open' : ''}`} onClick={handleClose}></div>
    <div className={`headermenubackground ${headermenubackgroundOpen ? 'open' : ''}`} onClick={handleClose}></div>
    <div class={`shopping-cart ${CartOpen ? 'open' : ''}`}>
        <div class="shopping-cart-heading">Cart
          <div class="dashboard-navbar-close-flex" onClick={handleClose}>
            <div class="dashboard-navbar-close-icon"></div>
          </div>
        </div> 
              <div class="shopping-cart-products">
              {products.length === 0 ? (

              <div id='cartEmty' class="no-items-in-cart">No items in cart</div>

              ) : ( 
                <> 
                 
                {products?.map((item) => (
                      <div class="shopping-cart-product-container" key={item.id}>
                          <img
                              alt=""
                              src={process.env.REACT_APP_UPLOAD_URL+item.img}
                              class="shopping-cart-product-image"
                          />
                          <div class="shopping-cart-product-content">
                            <div class="shopping-cart-product-content-name">
                              {item.name}
                              <div class="shopping-cart-product-content-duration">{item.duration}</div>
                            </div>
                           
                                
                          
                          <div class="shopping-cart-product-content-counter">
                           

                            <div class="shopping-cart-product-content-counter-plus-minus" onClick={() =>
                              dispatch(
                                itemQuanMinus({
                                  id: item.id,
                                  duration: item.duration,
                                  quantity, 
                                })
                            )}>
                             
                            </div>


                      <div class="shopping-cart-product-content-counter-value">{item.quantity}</div>

                      <div
                        class="shopping-cart-product-content-counter-plus-minus"
                        onClick={async () => {
                          if (item.keyCount <= item.quantity ) {
                            toast.error(`No more keys available`, {
                              position: "bottom-center",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                              toastId: "1",
                              transition: Slide,
                            });
                          } else {
                            dispatch(
                              itemQuanPlus({
                                id: item.id,
                                duration: item.duration,
                                quantity,
                              })
                            );
                          }
                        }}
                      >
                          +
                      </div>

                        </div>
                       </div> 
                        <div class="shopping-cart-product-content-price">
                          <div class="shopping-cart-product-content-price-text">{(item.Price * item.quantity).toFixed(2)}€</div>
                        </div>
                      </div>
                    ))}

                    

                    <div class="payment-options">
                      <div class="shopping-cart-total-amount-container">
                        <div class="shopping-cart-total-amount-text">Total Amount</div>
                        <div class="shopping-cart-total-amount-price">{totalPrice()}€</div>
                      </div>

                      <button 
                        class={`payment-option w-inline-block ${!isCardEnabled ? 'payment-option-disabled' : ''}`}
                        onClick={() => handlePayment("card")} type="button"
                        disabled={!isCardEnabled}
                      >
                      <div class="payment-option-text">
                        {!isHovering && (
                          <>
                          <span className="hidden-options2">
                            Card, Apple/Google Pay,  <br/>iDEAL, EPS & 
                          </span>
                          <span 
                          className="more-options"
                          onMouseEnter={() => setIsHovering(true)}
                        >
                          more
                        </span>
                          
                          </>
                        )}
                      
                        {isHovering && (
                        <>
                          <span className="hidden-options2" >
                          Bancontact, giropay,<br/> Klarna, P24 & 
                          </span>
                          <span 
                            className="more-options"
                            onMouseLeave={() => setIsHovering(false)}
                          >
                            more
                          </span>
                        </>
                        )}
        
                        <span class="via-gateway-span">via </span><span class="stripe-span">Stripe</span></div>
                          <div class="payment-options-icons">
                            <div class="payment-method-icon-container"><img src={Mastercard_logo} loading="lazy" width="27" sizes="100vw" alt="" class="payment-method-icon"/></div>
                            <div class="payment-method-icon-container"><img src={Visa_logo} loading="lazy" width="36" sizes="100vw" alt=""  class="payment-method-icon"/></div>
                            <div class="payment-method-icon-container">
                              <div class="bank-transfer-icon"></div>
                            </div>
                          </div>
                      </button>

                      
                      <button
                        class={`payment-option w-inline-block ${!isPaypalEnabled ? 'payment-option-disabled' : ''}`}
                        onClick={() => handlePayment("paypal")} type="button"
                        disabled={!isPaypalEnabled}
                      >
                          <div class="payment-option-text">PayPal <span class="via-gateway-span">via </span><span class="stripe-span">Stripe + 0.50€ fee</span></div>
                          <div class="payment-options-icons">
                            <div class="payment-method-icon-container"><img src={PayPal_Logo} loading="lazy" width="20" sizes="100vw" alt="" class="payment-method-icon"/></div>
                          </div>
                      </button>
                      <div class="agreeTerms-nav">By purchasing you agree to our <Link class="agreeTerms-link" to="/tos"> Terms of Service</Link></div>
                    </div>  
                </>   
              )}

            </div>
      
        </div>
        
    </>
 )
}

export default Navbar
