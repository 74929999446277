import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Countdown from "react-countdown";
import { makeRequest } from '../../makeRequest';

const Login = () => {
  const [emailLogin, setEmailLogin] = useState('');
  const [error, setError] = useState(null);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const redirectToCheckout = localStorage.getItem('redirectToCheckout');
  const shouldDisplayTrueText = redirectToCheckout === 'true';
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdownDate, setCountdownDate] = useState(0);
  const [countdownKey, setCountdownKey] = useState(Date.now());
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const handleEmailLoginChange = (event) => {
    const email = event.target.value;
    setEmailLogin(email);
    /*
    Sanitising
    */ 
  };

  const handleLogin = () => {
    makeRequest.post('/passwordless/send-link', {
        email: emailLogin,
      })
      .then(() => {
        setIsLinkSent(true);
        setIsLoading(false);
        setResendDisabled(true);
        const endDate = Date.now() + 180000;
        localStorage.setItem("a1b2c3d4e5", JSON.stringify(endDate));
        setCountdownDate(endDate);
        setCountdownKey(Date.now());
        setIsCountdownActive(true);

        setTimeout(() => {
          setIsCountdownActive(false);
          setResendDisabled(false);
        }, 180000); // 1 Minute Timeout, ändern Sie dies entsprechend Ihrem Countdown
      })
      .catch((error) => {
        setError('Error sending link');
        setIsLoading(false);
        console.error('Error sending link:', error);
      });
  };

  const handleResendEmail = () => {
    setResendDisabled(true);
    handleLogin();
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div>
          {isLinkSent ? (
            <div class="login-button-flex">
              <button onClick={handleResendEmail} disabled={false} class="login-button w-inline-block">
                <div class="login-button-text">Resend Email</div>
              </button>
            </div>
          ) : (
            <div class="login-button-flex">
              <button onClick={handleLogin} disabled={false} class="login-button w-inline-block">
                <div class="login-button-text">Login</div>
              </button>
            </div>
           )}
        </div>
      );
    } else {
      return (
         <div>
          {isLinkSent ? (
            <div class="login-button-flex">
              <button disabled={true} class="login-button-disabled w-inline-block">
                <div class="login-button-text">Resend Email</div>
              </button>
            </div>
          ) : (
            <div class="login-button-flex">
              <button  disabled={true} class="login-button-disabled w-inline-block">
                <div class="login-button-text">Login</div>
              </button>
            </div>
           )}
       
          <span>
            <div class="verification-can-be-resend-in login-button-flex">Can be resend in: {minutes}:{seconds}</div>
          </span>
        </div>
      );
    }
  };

  if(localStorage.getItem("a1b2c3d4e5") === null){
    localStorage.setItem("a1b2c3d4e5", JSON.stringify(Date.now()));
  }

  useEffect(() => {
    const savedDate = localStorage.getItem("a1b2c3d4e5");
    if (savedDate != null && !isNaN(savedDate)) {
      setCountdownDate(parseInt(savedDate, 10));
      setIsCountdownActive(true);
    }

    // Überprüfen Sie beim Laden der Seite, ob der Countdown noch aktiv ist
    if (isCountdownActive) {
      setResendDisabled(true);
    }
  }, [isCountdownActive]);

  return (
    <>
    <div class="login-con">
      {isLinkSent ? (
        <div class="verification-flex">
          <div class="verification-icon"></div>
          <div class="verification-email-has-been-sent">Verification email has been sent<br/></div>
          <div class="verification-email-help">Unable to find the email? Check your spam folder or click the resend button.‍</div>
        </div>
      ) : (
      <>
      <div class="login-page-heading-flex">
        {shouldDisplayTrueText ? (
          <div class="login-page-heading-redirected">You will be redirected to Checkout after Sign Up/Login</div>
        ) : (
          <>
          <div class="login-page-small-heading">Dashboard</div>
          <div class="login-page-heading">Sign Up/Login to your dashboard</div>
          </>
        )}   
      </div>
        <div class="login-flex">
        <div class="login-field-flex">
          <div class="login-flex-2">
            <div class="login-page-email-label">Email Address</div>
            <div class="login-page-container">
              <div class="login-page-icon"></div>
              <div class="login-page-input-block w-form">
                <form>
                  <input
                    id="email"
                    value={emailLogin}
                    onChange={handleEmailLoginChange}
                    type="email" 
                    class="login-page-input w-input" 
                    maxlength="256" 
                    name="email" 
                    data-name="Email" 
                    placeholder="" 
                    required=""
                    disabled={isLoading}
                  />
                </form>
                <div class="success-message w-form-done"></div>
                <div class="error-message w-form-fail"></div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>

      )}

      <div>
      {isCountdownActive && (
        <Countdown
          key={countdownKey}
          date={countdownDate}
          renderer={renderer}
        />
      )}
      <div class="login-button-flex">
      {isLinkSent ?"": (
        <>
          {error && <div class="invalid-email-address">Invalid email address.</div>}
        </>
      )}
        
      </div>
      </div>
      </div>
      </>
  );
};

export default Login;